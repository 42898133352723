import { ActionTypes } from '../../main/biInterfaces';
import { Status } from '@wix/ambassador-challenges-v1-approval-request/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { handleError } from '../../ErrorHandler/errorHandlerPropsMap';
import { biBeforeAndAfter } from '../../../services/biHelpers';
import {
  listApprovalRequests,
  resolveApprovalRequest,
} from '@wix/ambassador-challenges-v1-approval-request/http';
import { request } from '../../../services/request';

async function getApprovalRequestId(
  flowAPI: ControllerFlowAPI,
  challengeId: string,
) {
  try {
    const approvals = (
      await request({
        flowAPI,
        options: listApprovalRequests({
          challengeId,
          creatorId: flowAPI.controllerConfig.wixCodeApi.user.currentUser.id,
          includeResolved: false,
        }),
        errorOptions: {
          errorCodesMap: {
            applicationError: {},
          },
        },
      })
    ).data;

    return approvals?.requests?.['0']?.id;
  } catch (error) {
    handleError({ error, context: 'cancelInvite.listApprovalRequests' });
  }
}

export async function cancelInvite(
  flowAPI: ControllerFlowAPI,
  challengeId: string,
) {
  const approvalRequestId = await getApprovalRequestId(flowAPI, challengeId);
  await request({
    flowAPI,
    options: resolveApprovalRequest({
      actionId: 'test',
      requestId: approvalRequestId,
      resolutionStatus: Status.CANCELLED,
    }),
    errorOptions: {
      errorCodesMap: {
        applicationError: {},
      },
    },
  });

  if (approvalRequestId) {
    await biBeforeAndAfter(
      flowAPI.bi,
      ActionTypes.CHALLENGE_JOIN,
      async (actionId) => {
        return (
          await request({
            flowAPI,
            options: resolveApprovalRequest({
              actionId,
              requestId: approvalRequestId,
              resolutionStatus: Status.CANCELLED,
            }),
            errorOptions: {
              errorCodesMap: {
                applicationError: {},
              },
            },
          })
        )?.data;
      },
    );
  } else {
    console.error("[Challenges]: Can't find approval request id.", {
      flowAPI,
    });
    return;
  }
}
