import { ResolutionStatus } from '@wix/ambassador-challenges-v1-participant/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IResolveStepContext, ResolveStepParams } from './ResolveStepContext';
import { userProviderPropsMap } from '../User/userProviderPropsMap';
import { handleError } from '../ErrorHandler/errorHandlerPropsMap';

import { FedopsInteraction } from '../../config/fedopsInteraction';
import { monitoringPropsMap } from '../Monitoring/MonitoringPropsMap';
import { showToastFromController } from '../ToastContext/toastPropsMap';
import {
  resolveParticipantStepV3,
  ResolveStepData,
  undoStepV3,
} from '../storage-contexts/Challenge/api/resolveStep';
import { isFailed } from '../../selectors/step';
import { ParticipantStep, ParticipantStepState } from '../../types/v3Types';

type ResolvedStep = Pick<
  ParticipantStep,
  'quizSubmission' | 'transitions' | 'id'
>;

export function isParticipateStepQuizFailed(step: ResolvedStep): boolean {
  return !!(
    step.transitions[0].state === ParticipantStepState.RUNNING &&
    step.quizSubmission?.quizSubmissionId
  );
}

export const patchParticipantStep = (step: ResolvedStep): ResolvedStep => {
  if (isParticipateStepQuizFailed(step)) {
    return {
      ...step,
      transitions: [
        {
          state: ParticipantStepState.FAILED,
          occurredAt: step.transitions[0].occurredAt,
        },
        ...step.transitions,
      ],
    };
  }
  return step;
};

const resolveStep = async (
  flowAPI: ControllerFlowAPI,
  payload: ResolveStepParams,
): Promise<void> => {
  const {
    stepId,
    quizSubmission,
    unresolvedMessage,
    status = ResolutionStatus.COMPLETED,
    isUpdate = false,
  } = payload;
  const { participant, participantV3 } = await userProviderPropsMap(flowAPI);
  const participantOrV3 = participantV3 || participant;

  flowAPI.controllerConfig.setProps({
    isResolveStepRequestInProgress: true,
  });

  const sendRequest = async () => {
    if (status === ResolutionStatus.UNDO) {
      return undoStepV3(
        {
          participantId: participantOrV3.id,
          stepId,
        },
        flowAPI,
      );
    }
    return resolveParticipantStepV3(
      {
        participantId: participantOrV3.id,
        stepId,
        quizSubmissionId: quizSubmission?.quizSubmissionId,
      },
      flowAPI,
    );
  };

  try {
    const { step: originalStep } = await sendRequest();

    const step = patchParticipantStep(originalStep);

    if (isFailed(step)) {
      showToastFromController(flowAPI, unresolvedMessage);
    }

    const resolveStepData: ResolveStepData = {
      id: step?.id,
      quizSubmission: step?.quizSubmission,
      transitions: step?.transitions,
    };

    flowAPI.controllerConfig.setProps({
      resolveStepData,
    });
    // participantSectionsPropsMap?.cache?.clear(); // the cache needs to be cleared because sections and steps data is changed now

    if (!isUpdate && !isFailed(step)) {
      const userProvider = await userProviderPropsMap(flowAPI);
      // backend progress update is async, so we do optimistic update
      await userProvider.incrementParticipantsCompletedStepSummary(
        status !== ResolutionStatus.UNDO ? 1 : -1,
      );
    }

    const { endInteraction } = await monitoringPropsMap(flowAPI);
    // eslint-disable-next-line no-nested-ternary
    const interactionName = isUpdate
      ? FedopsInteraction.UpdateFeedbackData
      : FedopsInteraction.CompleteStepWithoutFeedback;
    endInteraction(interactionName, stepId);
  } catch (error) {
    handleError({
      error,
      context: 'resolveStep',
    });

    flowAPI.controllerConfig.setProps({
      resolveStepError: error.toString(),
    });

    console.error(error);
  }

  flowAPI.controllerConfig.setProps({
    isResolveStepRequestInProgress: false,
  });
};

export const resolveStepDataProviderPropsMap = async function (
  flowAPI: ControllerFlowAPI,
): Promise<Partial<IResolveStepContext>> {
  return {
    isResolveStepRequestInProgress: false,
    resolveStep: async (payload) => resolveStep(flowAPI, payload),
  };
};
